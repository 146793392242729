import React from 'react';
const Navbar = () => {
    return (
        <nav className="navbar">

                    <ul>
                        <li><a href="https://twitter.com/prosandconsnft" target="_blank"><img src="./img/twitter.png"/></a></li>
                        <li><a href="https://discord.gg/Kd28AW9jCQ" target="_blank"><img src="./img/discord.png"/></a></li>
                        <li><a href="https://opensea.io/collection/couturenightout" target="_blank"><img src="./img/opensea.png"/></a></li>
                    </ul>

        </nav>
    );
}

export default Navbar;