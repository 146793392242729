import React from 'react';
const Intro = () => {
    return (
        <div className="intro-bg">
            <div className="container">
                <div className="row intro-container">


                    <div className="col-lg-6 col-sm-12">
                        <div className="intro-text">
                            <div className="slogan">
                                <h3>About the Collection</h3>
                            </div>
                            <p>Couture Night Out is a collection of 7500 diverse fashion models that are digitally hand drawn using water colours wearing Pros&Cons fashion designs.  Our mission is to raise attention to ethically made and slow fashion, gender equality within the garment industry, and the empowerment of all women, girls, and those who identify as female.
                            </p>
                            <p>Part of this collection will feature very rare models wearing a crown that will give you exclusive access to a Coupon Code to redeem for a handmade Featured Dress based on one of the designs in the Couture Night Out. Featured Dress is handmade by Alana exclusively for you. </p>
                            <a href="https://prosconsnft.com/couture-night-out" target="_blank"  rel="noopener noreferrer" className="elegant elegant-dark" >LEARN MORE</a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="imgLoader"></div>

                        <div className="book-container">
                            <div className="book">
                                <div className="gap"></div>
                                <div className="pages">
                                    <div className="page"></div>
                                    <div className="page"></div>
                                    <div className="page"></div>
                                    <div className="page"></div>
                                    <div className="page"></div>
                                    <div className="page"></div>
                                </div>
                                <div className="flips">
                                    <div className="flip flip1">
                                        <div className="flip flip2">
                                            <div className="flip flip3">
                                                <div className="flip flip4">
                                                    <div className="flip flip5">
                                                        <div className="flip flip6">
                                                            <div className="flip flip7"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row intro-container charity-text">
                    <div className="col-lg-4 col-sm-12 charity-logo">
                        <img src="/img/Charity-Logo.PNG" alt="" />
                    </div>
                    <div className="col-lg-8 col-sm-12">
                        <div className="slogan">
                            <h3>Giving Back</h3>
                        </div>
                        <p>20% of earnings will be going to The Canadian Women’s Foundation support gender equality, women empowerment, and ethical fashion.
                        </p>
                    </div>
                </div>
            </div>
        </div>








    );
}

export default Intro;