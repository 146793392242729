// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import { fetchData } from "../data/dataActions";
import { debug } from "util";
require("cors");

var mintConfig = require("./config.json");

const WhitelistApiTest = () => {
  return {
    type: "WHITELIST_TEST_REQ",
  };
};

const WhitelistApiTestSuccess = (payload) => {
  return {
    type: "WHITELIST_TEST_REQ_SUCCESS",
    payload: payload,
  };
};

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const supplyRequest = () => {
  return {
    type: "SUPPLY_REQUEST",
  };
};

const supplyRequestSuccess = (payload) => {
  return {
    type: "SUPPLY_REQUEST_SUCCESS",
    payload: payload,
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const checkWhitelistConnection = () => {
  return async (dispatch) => {
    dispatch(WhitelistApiTest());
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();
    if (CONFIG.TEST_WHITELIST_API) {
      fetch(
        CONFIG.WHITELIST_API + "'" + CONFIG.TESTADDRESS + "'",
        {
          headers: {
            "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
        .then(async (response) => {
          const mysign = await response.json();
          dispatch(
            WhitelistApiTestSuccess({
              WhitelistAPI: mysign.signature,
            })
          );
        })
        .catch((error) => {
          dispatch(connectFailed("WhitelistApi not available"));
          console.log(error);
        });
    }
  };
};

export const setOnloadSupply = () => {
  return async (dispatch) => {
    dispatch(supplyRequest());
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();
    const { ethereum } = window;
    
    Web3EthContract.setProvider(ethereum);
    let web3 = new Web3(ethereum);
    try {
      const networkId = await ethereum.request({
        method: "net_version",
      });

      if (networkId == CONFIG.NETWORK.ID) {
        const SmartContractObj = new Web3EthContract(
          abi,
          CONFIG.CONTRACT_ADDRESS
        );
        dispatch(
          supplyRequestSuccess({
            smartContract: SmartContractObj,
            web3: web3,
          })
        );
      }
    } catch (err) {
      //console.log(err);
      //dispatch(connectFailed('Something went wrong.'));
    }
  };
};

export const connect = (providerName) => {
  //console.log("connect pressed")
  return async (dispatch) => {
    dispatch(connectRequest());
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();
    const { ethereum } = window;
    
    let provider;
    switch (providerName) {
        case 'CoinBase':
            provider = ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet);
            break;
        case 'MetaMask':
            provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
            break;
    }

    if (provider) {
      ethereum.setSelectedProvider(provider);
  }

    
    if (ethereum && (ethereum.isMetaMask || ethereum.isCoinbaseWallet)) {
      Web3EthContract.setProvider(ethereum);
      let web3 = new Web3(ethereum);
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        const networkId = await ethereum.request({
          method: "net_version",
        });
        if (networkId == CONFIG.NETWORK.ID) {
          const SmartContractObj = new Web3EthContract(
            abi,
            CONFIG.CONTRACT_ADDRESS
          );

          let presaleActive = await SmartContractObj.methods
            .presaleActive()
            .call();
          let publicMintActive = await SmartContractObj.methods
            .mintActive()
            .call();

            //console.log(accounts[0])

          if (presaleActive || publicMintActive) {
            if (publicMintActive) {

              mintConfig.mintActive = true;
              
              dispatch(
                connectSuccess({
                  account: accounts[0],
                  smartContract: SmartContractObj,
                  web3: web3,
                })
              );
              // Add listeners start
              ethereum.on("accountsChanged", (accounts) => {
                dispatch(updateAccount(accounts[0]));
              });
              ethereum.on("chainChanged", () => {
                window.location.reload();
              });
            } else {
              fetch(CONFIG.WHITELIST_API + "'" + accounts[0] + "'", {
                headers: {
                  "Access-Control-Allow-Headers":
                    "Origin, X-Requested-With, Content-Type, Accept",
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              })
                .then(async (response) => {
                  const mysign = await response.json();
                  //console.log(mysign.signature);
                  if (response.status == 200) {
                    dispatch(
                      connectSuccess({
                        account: accounts[0],
                        smartContract: SmartContractObj,
                        web3: web3,
                        signature: mysign.signature,
                      })
                    );
                    // Add listeners start
                    ethereum.on("accountsChanged", (accounts) => {
                      dispatch(updateAccount(accounts[0]));
                    });
                    ethereum.on("chainChanged", () => {
                      window.location.reload();
                    });
                  } else {
                    dispatch(connectFailed("Caller is not whitelisted"));
                  }
                })
                .catch((error) => {
                  //console.log(error)
                  dispatch(connectFailed("Caller is not whitelisted"));
                  console.log("Caller is not whitelisted");
                });
            }
          } else {
            dispatch(connectFailed("Sale has not started"));
          }
        } else {
          dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
        }
      } catch (err) {
        //console.log(err);
        dispatch(connectFailed("Something went wrong."));
      }
    } else {
      dispatch(connectFailed("Install a supported wallet"));
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
