import React from 'react';
import $ from 'jquery'; 




$(window).scroll(function() {
    $('.slider-background').css("animation-play-state", "paused");
    if ($(this).scrollTop() === 0) {
        $('.slider-background').css("animation-play-state", "running");
    }

});


const Alert = () => {
    return (
        <div className="container-fluid px-0 overflow-hidden">
        <div className="row no-gutters">
        <div className="col-12">
        <div className="alert-container">
        <div className="slider-background">
        </div>
        </div>
        </div>
        </div>
        </div>
    );
}
 
export default Alert;