import "./index.scss";
import Navbar from "./components/Navbar.js";
// import Hero from './components/Hero';
import Alert from "./components/Alert";
import Intro from "./components/Intro";
import Coupon from "./components/Coupon";
import Footer from "./components/Footer";
import $ from "jquery";
import React, { useEffect, useState, useRef } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { fetchData } from "./redux/data/dataActions";
import {
  connect,
  setOnloadSupply,
  checkWhitelistConnection,
} from "./redux/blockchain/blockchainActions";
import Web3 from "web3";
import store from "./redux/store";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnect from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { ethers } from 'ethers';



export const providerOptions = {
  walletlink: {
    package: CoinbaseWalletSDK, 
    options: {
      appName: "Web 3 Modal Demo",
      infuraId: process.env.INFURA_KEY 
    }
  },
  /* walletconnect: {
    package: WalletConnect, 
    options: {
      infuraId: process.env.INFURA_KEY 
    }
  } */
 };
 const web3Modal = new Web3Modal({
  providerOptions // required

});


var mintConfig = require("./redux/blockchain/config.json");

$(window).scroll(function () {
  $(".twinkling").css("display", "none");
  if ($(this).scrollTop() === 0) {
    $(".twinkling").css("display", "block");
  }
});

//------------------------------------------

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [signature, setSignature] = useState("");
  const [_quantity, set_quantity] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    WHITELIST_API: "",
    TEST_WHITELIST_API: false,
    TESTADDRESS: "",
    FetchMintedSupplyByTimer: true,
    FetchMintedSUpplyInterval: 0,
  });

  const claimNFTsPresale = () => {
    let cost = CONFIG.WEI_COST;
    let totalCostWei = String(cost * _quantity);
    console.log("Cost: ", totalCostWei);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .presaleMint(_quantity, blockchain.signature)
      .send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        signature: blockchain.signature,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })
      .once("error", (err) => {
        setFeedback(
          "Sorry, something went wrong please try again later."
        );
        setClaimingNft(false);
        return;
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let totalCostWei = String(cost * _quantity);
    console.log("Cost: ", totalCostWei);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .publicMint(_quantity)
      .send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })
      .once("error", (err) => {
        setFeedback(
          "Sorry, something went wrong please try again later."
        );
        setClaimingNft(false);
        return;
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrement_quantity = () => {
    let new_quantity = _quantity - 1;
    if (new_quantity < 1) {
      new_quantity = 1;
    }
    set_quantity(new_quantity);
  };

  const increment_quantity = () => {
    let new_quantity = _quantity + 1;
    if (mintConfig.mintActive) {
      if (new_quantity > 10) {
        new_quantity = 10;
      }
    } else {
      if (new_quantity > 10) {
        new_quantity = 10;
      }
    }
    set_quantity(new_quantity);
  };

  const getData = () => {
    //console.log("getData");
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getSupplyInfo = () => {
    dispatch(setOnloadSupply());
  };

  const testWhitelist = () => {
    dispatch(checkWhitelistConnection());
  };

  const getDataSimple = () => {
    if (blockchain.smartContract !== null) {
      //console.log("get Minted Amount");
      //console.log(blockchain.smartContract)
      dispatch(fetchData());
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 10000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    //console.log(seconds);
    getDataSimple();
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  useEffect(() => {
    getConfig();
    getSupplyInfo();
    setIsActive(CONFIG.FetchMintedSupplyByTimer);
    setSeconds(CONFIG.FetchMintedSUpplyInterval);
    //console.log(blockchain.account);
  }, []);

  useEffect(() => {
    getData();
    //console.log(blockchain.account);
  }, [blockchain.account]);

  useEffect(() => {
    getDataSimple();
    //console.log("hook for contract");
    //console.log(blockchain.account);
  }, [blockchain.smartContract]);

  useEffect(() => {
    if (CONFIG.TEST_WHITELIST_API) {
      console.log("Whitelist Signature is: " + blockchain.WhitelistAPI);
    }
  }, [blockchain.WhitelistAPI]);

  useEffect(() => {
    if (CONFIG.TEST_WHITELIST_API) {
      testWhitelist();
    }
  }, [CONFIG.TEST_WHITELIST_API]);

//BEGIN MODAL FUNCTIONALITY//
const [provider, setProvider] = useState();
const [library, setLibrary] = useState();

const connectWallet = async () => {
  try {
    const provider = await web3Modal.connect();
    const library = new ethers.providers.Web3Provider(provider);
    
    
    //console.log(provider)
    dispatch(connect(provider));
    getData();
    setProvider(provider);
    setLibrary(library);
  } catch (error) {
    console.error(error);
  }
};



//END MODAL FUNCTIONALITY//


  return (
    //START MARKUP
    <div className="App">
      <Navbar />
      {/* <Hero /> */}

      <Provider store={store}>
        <div id="hero-container">
          <div className="stars"></div>
          <div className="twinkling"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-12 mint-info">
                <img
                  src="/img/CNO-Logo.png"
                  className="logo"
                  alt="Couture Night Out"
                />
                <div className="contract-details-card">
                  <span id="slogan">Find Your Fashion Empowerment Model</span>
                  <span className="price">{CONFIG.DISPLAY_COST} ETH</span>
                  {/* <span id="contract-ad"><strong>CONTRACT:</strong><br />[34sfake47contract35add98x]</span>
                                <span id="mint-price"><strong>COST:</strong><br />1 HODLME costs [COST].</span>
                                <span className="connect-line">Connect to the [NETWORKHERE] Network</span> */}
                  <h3 className="mint-count">{data.totalSupply} / {CONFIG.MAX_SUPPLY} MINTED{" "}</h3>

                  {/* <div><span className="network">Connect to the {CONFIG.NETWORK.NAME} network</span></div> */}
<div className="minting-buttons">
                  {blockchain.account == null ? (
                    <>
                      <button
                        className="elegant"
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   // Open modal here to get account
                        //   // THen Call Dispatch(COnnect from modal)
                        //   dispatch(connect());
                        //   getData();
                        // }}
                        onClick={connectWallet}
                      >
                        CONNECT
                      </button>
                      {blockchain.errorMsg !== "" ? (
                        <>{blockchain.errorMsg}</>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {feedback}
                      <div className="minting-buttons-test">
                      <button
                      className="elegant-minters minus"
                       
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrement_quantity();
                        }}
                      >
                        -
                      </button>
                      
                      <span className="mint-quantity">{_quantity}</span>
                      <button
                      className="elegant-minters"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          increment_quantity();
                        }}
                      >
                        +
                      </button> 
</div>
                      <button
                        className="elegant"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          //console.log(blockchain.account);
                          e.preventDefault();
                          if (mintConfig.mintActive) {
                            claimNFTs();
                          } else {
                            claimNFTsPresale();
                          }
                          getData();
                        }}
                      > 
                        {claimingNft ? "BUSY" : "BUY"}
                      </button>
                    </>
                  )}

                  {/* <span className="price">{CONFIG.DISPLAY_COST} ETH EACH</span> */}
                </div>
              </div>
</div>
              <div className="foil-image">
                <div className="img"></div>
              </div>
            </div>
          </div>
        </div>
      </Provider>

      <Alert />
      <Intro />
      <Coupon />
      <Footer />
    </div>
  );
}

export default App;
