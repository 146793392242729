import React from 'react';
const Footer = () => {
    return (
<div className="footer">
            <div className="footer-social">
            <div className="container">
            <div className="row">   
            <div className="col-lg-12 col-sm-12">
            <ul>
                        <li><a href="https://twitter.com/prosandconsnft" target="_blank"><img src="./img/twitter.png"/></a></li>
                        <li><a href="https://discord.gg/Kd28AW9jCQ" target="_blank"><img src="./img/discord.png"/></a></li>
                        <li><a href="https://opensea.io/collection/couturenightout" target="_blank"><img src="./img/opensea.png"/></a></li>
                    </ul>
                    </div>
                    <div className="container">
                    <div className="row">  
                    <div className="col-lg-12 col-sm-12 float-left">
                    <p>© Couture Night Out 2022</p>
                    </div>
                    </div>
                    <div className="col-lg-12 col-sm-12 powered-by">
            <ul>
                        <li><a href="https://www.musee-a.org/" target="_blank"><img src="./img/museelogowhite.png"/></a></li>
                        <li><a href="https://www.musee-a.org/" target="_blank">Powered by The Meta Musee</a></li>
                    </ul>
                    </div>
                    </div>
                    

                    </div>
            </div>
            </div>
        </div>

  
        
    );
}
 
export default Footer;





