
import React from 'react';


const Coupon = () => {
    return (
        <div className="container-fluid">
        <div className="container coupon-container">
                    <div className="row">
                    <div className="col-md-12 col-sm-12">
                    <h3 className="coupon-header">CLAIM AND VERIFY YOUR FEATURED DRESS</h3>
                    <p>Coming Soon! The ability to claim the coupon code for the ultra-rare Royalty dresses and verify if the coupon code has been used. Once these features are live you will be able to come back here, connect your wallet to prove ownership of the NFT with the Royalty trait and claim your custom dress. You will also be able to confirm if a NFT with a royalty trait has claimed a coupon code.</p>
                    <p>For more information see the FAQs section in our main website.</p>
                <button className="elegant disabled" onClick={(e) => {
                                      e.preventDefault();
                                    //   dispatch(connect());
                                    //   getData();
                                }}>COMING SOON</button>
        </div>
        {/* <div className="col-md-6 col-sm-12">
        <h3 className="coupon-header">CHECK IF YOUR COUPON <br></br>HAS BEEN CLAIMED</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa ipsam iure recusandae, sapiente odit atque praesentium doloribus vitae, eaque neque similique laborum cumque laboriosam impedit reprehenderit eius illo quam itaque.</p>
                <button className="elegant" onClick={(e) => {
                                    //   e.preventDefault();
                                    //   dispatch(connect());
                                    //   getData();
                                }}>CLAIM COUPON</button>

        </div> */}
        </div>
        </div>
        </div>
    );
}
 
export default Coupon;
